
import debounce from "lodash/fp/debounce";
import Vue from "vue";

export default Vue.extend({
  name: "LiveSearch",

  props: {
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    moduleName: {
      type: String,
      required: false,
      default: ""
    },
    searchFunction: {
      type: Function,
      required: true
    },
    value: {
      required: false,
      default: () => ({})
    },
    rules: {
      required: false,
      type: Array,
      default: () => []
    },
    errorMessage: {
      required: false,
      default: ""
    },
    allowText: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    debounce,
    debounced: null as any,
    disabledInput: false as boolean,
    search: "",
    loading: false as boolean,
    model: null as any,
    items: [] as Array<SelectComponentInterface>
  }),

  watch: {
    search() {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(500, this.searchQuery);
      this.debounced();
    },
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    },
    disabled: {
      immediate: true,
      handler() {
        this.disabledInput = this.disabled;
      }
    }
  },

  methods: {
    async searchQuery() {
      this.loading = true;
      try {
        if (this.search) {
          const { items } = await this.searchFunction(
            String(this.search),
            this.moduleName
          );
          this.items = items;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    changeData() {
      this.$emit("input", this.model);
    },
    clear() {
      this.model = null;
      this.changeData();
      this.$emit("clear");
    },
    checkInput(): void {
      this.$emit("blur", this.model);
    },
    input(text: string) {
      if (this.allowText) {
        return;
      }

      if (text && text !== this.model?.text) {
        this.model = { text, value: null };
      }
    },
    blur() {
      if (this.allowText) {
        this.$emit("blur", this.search);
        return;
      }

      if (!this.model?.value) {
        this.model = null;
      }

      this.$emit("blur", this.model);
    }
  }
});
