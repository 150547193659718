
import Vue from "vue";

export default Vue.extend({
  name: "CommentsPreview",

  props: {
    comment: {
      type: Object,
      required: true,
      default: () => ({})
    },
    right: {
      type: [Number, String],
      required: false,
      default: 50
    },
    top: {
      type: [Number, String],
      required: false,
      default: 16
    }
  },

  watch: {
    comment: {
      immediate: true,
      handler() {
        this.model = this.comment;
        this.message = this.comment?.text;
      }
    }
  },

  data: () => ({
    showComments: false as boolean,
    loadingComments: false as boolean
  })
});
