import Vue from "vue";
export default Vue.extend({
  methods: {
    reload(): void {
      this.$emit("update");
    },
    async changeOrderStatus(alias: string): Promise<void> {
      try {
        if (alias) {
          await this.$API
            .orders()
            .changeOrderStatus(Number(this.$route.params.id), alias);

          await this.$emit("update");
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
