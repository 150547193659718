
import Vue from "vue";

export default Vue.extend({
  name: "Comments",

  props: {
    field: {
      type: String,
      required: true,
      default: ""
    },
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    right: {
      type: String,
      required: false,
      default: "0"
    },
    top: {
      type: String,
      required: false,
      default: "5px"
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.model = this.value;
        this.model.key = this.field;
      }
    }
  },

  data: () => ({
    model: {} as any,
    dialog: false as boolean
  }),

  methods: {
    updateComment() {
      this.$emit("update", this.model);
    },
    openCommentDialog() {
      this.toggleComments();
      (this.$refs.comments as any).focus();

      // setTimeout(() => {
      //   }, 00);
    },
    toggleComments(): void {
      this.dialog = !this.dialog;
    }
  }
});
