
import Vue from "vue";

export default Vue.extend({
  name: "FileUploader",

  components: {},

  props: {
    value: {
      required: false,
      default: null
    },
    label: {
      required: false,
      type: String,
      default: ""
    },
    rules: {
      required: false,
      type: Array,
      default: () => [] as Array<any>
    },
    errorMessages: {
      required: false,
      type: Array,
      default: () => [] as Array<any>
    },
    accept: {
      required: false,
      type: String,
      default: ""
    },
    dense: {
      required: false,
      type: Boolean,
      default: false
    },
    outlined: {
      required: false,
      type: Boolean,
      default: false
    },
    hideDetails: {
      required: false,
      type: Boolean,
      default: false
    },
    preview: {
      required: false,
      type: String,
      default: ""
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    truncateLength: {
      required: false,
      type: String,
      default: ""
    },
    maxFileSize: {
      required: false,
      type: Number,
      default: 10000000
    }
  },

  data: () => ({
    model: null as File | null | any,
    imagePreview: "" as string | ArrayBuffer | null
  }),

  watch: {
    preview: {
      immediate: true,
      handler() {
        this.imagePreview = this.preview;
      }
    },
    value: {
      immediate: true,
      handler() {
        if (typeof this.value === "string") {
          this.model = new File([], this.value || "");
        } else {
          this.model = this.value;
        }
      }
    }
  },

  methods: {
    toBase64(file: File): Promise<string | ArrayBuffer | null> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async changeData(): Promise<void> {
      try {
        if (this.model && this.model.size > this.maxFileSize) {
          const size = this.parseSize(this.maxFileSize);
          await this.$store.dispatch(
            "alert/showError",
            // `Max file size: ${}`
            this.$t("global_validation.max_file_size", {
              size
            })
          );
          this.$emit("input", null);
          this.model = null;
          return;
        }
        if (this.model?.type?.includes("image")) {
          this.imagePreview = await this.toBase64(this.model);
        } else {
          this.imagePreview = "";
        }
        this.$emit("input", this.model);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e);
      }
    },
    async downloadFile(file: any) {
      if (file) {
        const element = document.createElement("a");

        if (file.url) {
          element.setAttribute("href", file.url);
        } else {
          const fileLink = (await this.toBase64(file)) as string;
          element.setAttribute("href", fileLink);
          element.setAttribute("download", file.name);
        }

        element.setAttribute("target", "_blank");
        element.click();
      }
    },
    parseSize(size: number) {
      if (size < 1000) {
        return `${size} Bytes`;
      }

      if (size >= 1000 && size < 1000000) {
        return `${Math.round(((size / 1000) * 100) / 100)} KB`;
      }

      if (size >= 1000000 && size < 1000000000) {
        return `${Math.round(((size / 1000000) * 100) / 100)} MB`;
      }
    }
  }
});
