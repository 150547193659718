
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import FileUploader from "@/components/form-components/FileUploader.vue";
import CreatePayment from "@/modules/orders/components/dialogs/CreatePayment.vue";
import CreateGuarantee from "@/modules/orders/components/dialogs/CreateGuarantee.vue";

export default Vue.extend({
  name: "Payments",
  components: { CreatePayment, FileUploader, CreateGuarantee },
  directives: { mask },

  props: {
    value: {
      type: Object,
      required: true
    },
    loadingPayments: {
      type: Boolean,
      default: false,
      required: false
    },
    showGuarantee: {
      type: Boolean,
      default: false,
      required: false
    },
    dataChanged: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    rules,
    model: {} as any,
    confirmationFile: {} as any,
    isLoading: false as boolean,
    isLoadingGuarantee: false as boolean,
    newPaymentDialog: false as boolean,
    newGuaranteeDialog: false as boolean,
    isLoadingCreate: false as boolean,
    newPaymentModel: {} as any,
    newGuaranteeModel: {} as any,
    paymentMethods: [] as Array<any>,
    paymentsLog: [] as Array<any>,
    guaranteeLog: [] as Array<any>,
    services: [] as Array<any>,
    orderServices: [] as Array<any>,
    beneficiaryGuarantees: [] as Array<any>,
    invoices: [] as Array<any>,
    types: ["bank_deposit", "guaranty_letter"] as Array<any>,
    mask: /^\d*\.?\d*$/,
    errorMessages: {} as any
  }),

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.model = this.value;

        if (this.value.details.services) {
          this.orderServices = this.value.details.services;
        }

        this.confirmationFile = this.model.order.confirmation_file || {};
      }
    },
    loadingPayments: {
      immediate: true,
      handler() {
        if (this.loadingPayments) {
          this.getData();
        }
      }
    },
    totalPayed: {
      immediate: true,
      handler() {
        this.$emit("setPaymentsPrice", this.totalPayed);
      }
    }
  },

  computed: {
    disabledAddPayment() {
      return (
        this.isLoadingCreate ||
        !this.newPaymentModel.payment_method_id ||
        !this.newPaymentModel.order_invoice_id ||
        !this.newPaymentModel.proof_document
      );
    },
    totalPayed() {
      return this.paymentsLog.reduce(
        (total: number, { sum }: { sum: number }) => {
          if (sum) {
            return total + Number(sum);
          }
          return total;
        },
        0
      );
    },
    totalGuaranteePaid() {
      return this.guaranteeLog.reduce(
        (total: number, { sum }: { sum: number }) => {
          if (sum) {
            return Math.floor((total + Number(sum)) * 100) / 100;
          }
          return Math.floor(total * 100) / 100;
        },
        0
      );
    },
    servicesVatTotal() {
      return this.orderServices?.reduce(
        (totalSumNoVat: number, { sum_with_vat }: { sum_with_vat: number }) => {
          if (sum_with_vat) {
            return totalSumNoVat + Number(sum_with_vat);
          }
          return totalSumNoVat;
        },
        0
      );
    },
    leftToPay() {
      return this.servicesVatTotal - Number(this.totalPayed);
    },
    guaranteeLeftToPay() {
      return Math.max(
        this.model.details.required_guaranty_sum -
          Number(this.totalGuaranteePaid),
        0
      );
    },
    showExistingGuarantees() {
      return this.newGuaranteeModel.type === "existing_guarantee";
    }
  },

  async mounted() {
    await this.getData();
    this.checkInvoice();
  },

  methods: {
    async removeGuarantee(index: number, paymentId: number) {
      try {
        await this.$API.guarantees().delete(paymentId);
        this.$emit("update");
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.getOrderGuarantees();
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async unlinkGuarantee(index: number, paymentId: number) {
      try {
        await this.$API.guarantees().unlinkFromOrder(paymentId);
        this.guaranteeLog.splice(index, 1);
        this.$emit("update");
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async getData(): Promise<void> {
      this.isLoading = true;
      this.isLoadingGuarantee = true;
      try {
        const [paymentMethods, guaranteeTypes, invoices] = await Promise.all([
          this.$API.orders().getPaymentMethods(),
          this.$API.guarantees().getPaymentTypes(),
          this.$API.invoices().getOrderInvoices(this.model.order.id)
        ]);
        await this.getOrderGuarantees();
        await this.getOrderPayments();

        this.paymentMethods = paymentMethods;

        if (paymentMethods.length === 1) {
          const [payment] = paymentMethods;
          this.newPaymentModel.payment_method_id = payment.value;
        }

        this.types = guaranteeTypes;
        this.invoices = invoices;
        if (this.invoices.length === 1) {
          const [invoice] = this.invoices;
          this.newPaymentModel.order_invoice_id = invoice.value;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.isLoading = false;
      this.isLoadingGuarantee = false;
    },
    async getOrderPayments(): Promise<void> {
      this.isLoading = true;
      try {
        this.paymentsLog = await this.$API
          .payments()
          .getOrderPayments(Number(this.$route.params.id));
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async getOrderGuarantees(): Promise<void> {
      this.isLoadingGuarantee = true;
      try {
        if (this.showGuarantee) {
          this.guaranteeLog = await this.$API
            .guarantees()
            .getOrderGuarantees(Number(this.$route.params.id));
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoadingGuarantee = false;
    },
    downloadFile(file: any) {
      const element = document.createElement("a");
      element.setAttribute("href", file.url);
      element.setAttribute("target", "_blank");
      element.click();
    },
    toggleDialog(): void {
      this.newPaymentDialog = !this.newPaymentDialog;
    },
    toggleGuaranteeDialog(): void {
      this.newGuaranteeDialog = !this.newGuaranteeDialog;
    },
    closeGuarantee() {
      this.newGuaranteeDialog = false;
      this.newGuaranteeModel = {
        sum: 0
      };
    },
    async addGuarantee() {
      this.isLoadingGuarantee = true;
      try {
        if ((this.$refs.form as Vue).validate()) {
          if (this.showExistingGuarantees) {
            await this.$API
              .guarantees()
              .addExistingGuaranteeToOrder(
                this.newGuaranteeModel.guarantee_id,
                {
                  order_id: this.$route.params.id
                }
              );
          } else {
            const model = { ...this.newGuaranteeModel };
            model.order_id = this.$route.params.id;
            model.client_id = this.model.order.client.id;
            model.status = "confirmed";

            await this.$API.guarantees().create(model);
          }

          this.newGuaranteeDialog = false;

          await this.getOrderGuarantees();

          this.newGuaranteeModel = {
            sum: 0
          };
          this.$emit("update");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 3000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.isLoadingGuarantee = false;
    },
    async getBeneficiaryGuarantees(): Promise<void> {
      try {
        if (this.showExistingGuarantees) {
          this.beneficiaryGuarantees = await this.$API
            .guarantees()
            .getBeneficiaryGuarantees(this.model.order.client.id);

          this.newGuaranteeModel.proof_document = null;
          this.newGuaranteeModel.sum = 0;
        } else {
          if (this.newGuaranteeModel.guarantee_id) {
            this.newGuaranteeModel.sum = 0;
            this.newGuaranteeModel.proof_document = null;
            delete this.newGuaranteeModel.guarantee_id;
          }
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    setExistingGuarantee(guarantee: any) {
      this.newGuaranteeModel.sum = guarantee.sum;
      this.newGuaranteeModel.proof_document = guarantee.proof_document;
      this.newGuaranteeModel.guarantee_id = guarantee.id;
      this.$forceUpdate();
    },
    async checkUser(uuid: string): Promise<any> {
      return await this.http
        .ignoreGlobalPreloader()
        .post(`login_by_uuid`, { uuid });
    },
    checkInvoice(): void {
      if (this.$route.query.invoice) {
        if (
          this.invoices.some(
            (item: any) => item.value === Number(this.$route.query.invoice)
          )
        ) {
          this.newPaymentModel.order_invoice_id = Number(
            this.$route.query.invoice
          );
        }
        this.newPaymentDialog = true;
      }
    },
    update() {
      this.$emit("update");
    }
  }
});
