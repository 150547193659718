
import Vue from "vue";

export default Vue.extend({
  name: "OrderActions",

  props: {
    serviceType: {
      required: true,
      type: [Number, String]
    },
    order: {
      required: false,
      type: Object
    },
    isLoading: {
      required: false,
      default: false,
      type: Boolean
    },
    isTrustedData: {
      required: false,
      default: false,
      type: Boolean
    },
    bottom: {
      required: false,
      default: false,
      type: Boolean
    },
    isEdit: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    model: { order: {} as any } as any,
    loading: false as boolean
  }),

  computed: {
    isDraft() {
      return this.model?.status?.alias === "draft";
    },
    canDownloadCertificate() {
      return this.model?.status.alias === "issued";
    },
    disabledSubmit() {
      if (this.isEdit) {
        return this.loading;
      }
      return this.loading || !this.isTrustedData;
    },
    isEval() {
      return this.serviceType === 4;
    },
    isExp() {
      return this.serviceType === 5;
    },
    isIssued() {
      return this.model?.status?.alias === "issued";
    }
  },

  watch: {
    order: {
      immediate: true,
      deep: true,
      handler() {
        this.model = this.order;
      }
    },
    isLoading: {
      immediate: true,
      deep: true,
      handler() {
        this.loading = this.isLoading;
      }
    }
  },

  methods: {
    submit(event: any): void {
      this.$emit("submit", event);
    },
    cloneOrder(): void {
      this.$emit("cloneOrder");
    },
    async downloadDocument(): Promise<void> {
      try {
        const file = await this.$API
          .orders()
          .printAaijOrder(Number(this.$route.params.id));
        this.downloadFile(file);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async downloadExpDocument(): Promise<void> {
      window.open(
        `${process.env.VUE_APP_API_SERVER_URL}/orders/${this.$route.params.id}/pdf/expert_report`
      );
    },
    downloadFile(file: any) {
      const element = document.createElement("a");
      element.setAttribute("href", file.url);
      element.setAttribute("target", "_blank");
      element.click();
    },
    async close(): Promise<void> {
      if (Object.keys(this.$route.query).length) {
        await this.$router.push(this.$route.path);
      }
      await this.$router.push("/orders");
    },
    reload(): void {
      this.$emit("reload");
    },
    async changeOrderStatus(alias: string): Promise<void> {
      this.$emit("changeOrderStatus", alias);
    }
  }
});
