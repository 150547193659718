
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "CreatePayment",

  props: {
    newPaymentDialog: {
      type: Boolean,
      default: false,
      required: true
    },
    orderId: {
      required: true,
      type: Number
    },
    invoicePage: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    rules,
    model: {} as any,
    paymentMethods: [] as Array<any>,
    invoices: [] as Array<any>,
    errorMessages: {} as any,
    loading: false as boolean,
    mpayData: {} as any
  }),

  computed: {
    disabledAddPayment(): boolean {
      return (
        this.loading ||
        !this.model.payment_method_id ||
        !this.model.order_invoice_id ||
        !this.model.proof_document
      );
    }
  },

  async mounted(): Promise<void> {
    await this.loadData();

    if (this.invoicePage) {
      this.updateInvoicesText();
    }
  },

  methods: {
    async loadData(): Promise<void> {
      try {
        this.paymentMethods = await this.$API.orders().getPaymentMethods();
        this.invoices = await this.$API
          .invoices()
          .getOrderInvoices(this.orderId);

        if (this.paymentMethods.length === 1) {
          const [paymentMethod] = this.paymentMethods;
          this.model.payment_method_id = paymentMethod.value;
        }

        if (this.invoices.length === 1) {
          const [invoice] = this.invoices;
          this.model.order_invoice_id = invoice.value;
          await this.showPaymentsDialog(invoice.value);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    updateInvoicesText(): void {
      this.invoices = this.invoices.map(invoice => ({
        ...invoice,
        text: `${invoice.text}${invoice.guaranty_sum}`
      }));
    },
    toggleDialog() {
      this.$router.push(this.$route.path);
      this.$emit("update", null);
    },
    async createPayment() {
      this.loading = true;
      try {
        if ((this.$refs.form as Vue).validate()) {
          const model = { ...this.model };
          model.order_id = this.orderId;

          if (!model.comments) {
            delete model.comments;
          }

          await this.$API.payments().create(model);

          if (Object.keys(this.$route.query).length) {
            await this.$router.push(this.$route.path);
          }
          this.$emit("update");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 3000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async showPaymentsDialog(id: number): Promise<void> {
      try {
        this.mpayData = await this.$API.payments().getPaymentLink(id);
        await this.$store.dispatch("alert/showPayments");
      } catch (e) {
        console.log(e);
      }
    },
    makePayment() {
      (document.getElementById("form") as any).submit();
    }
  }
});
