import Vue from "vue";
export default Vue.extend({
  methods: {
    setAllocations({ branch_id, subdivision_id, handler_id }: any): void {
      this.model.order.branch_id = branch_id;
      this.model.order.subdivision_id = subdivision_id;
      this.model.order.handler_id = handler_id;
    }
  }
});
