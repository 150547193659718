import Vue from "vue";
export default Vue.extend({
  methods: {
    parseComments(array: any) {
      const object = {} as any;

      for (const key of array) {
        object[key.key] = key;
      }
      return object;
    },
    async updateComments(event: any) {
      if (event.text) {
        this.comments[event.key] = event;
      } else {
        delete this.comments[event.key];
      }

      this.$emit("input", this.comments);
      this.changeKey();
    }
  }
});
