
import Vue from "vue";
import permissions from "../config/actions-fields.json";

export default Vue.extend({
  name: "OrderServices",

  props: {
    value: {
      required: true,
      default: () => ({})
    },
    showGuarantee: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    oldValue: {
      required: false,
      type: Object,
      default: () => ({})
    },
    newServices: {
      required: false,
      default: () => ({})
    },
    isEdit: {
      required: false,
      default: false,
      type: Boolean
    },
    extraOutputs: {
      required: false,
      default: false
    },
    serviceTypeId: {
      required: false,
      default: null
    },
    role: {
      required: false,
      default: null,
      type: Object
    }
  },

  data: () => ({
    model: {} as any,
    goods: [] as Array<any>,
    outputs: null as any,
    beneficiary: null as any,
    releaseMode: null as any,
    ataExpositon: null as any,
    oldModel: null as any,
    services: {} as any,
    serviceId: null as any,
    orderServices: [] as Array<any>,
    totalRemainedForPayServiceSUm: 0 as number,
    totalPayedServiceSUm: 0 as number,
    servicesVatTotalSum: 0 as number,
    servicesTotalSum: 0 as number,
    forceKey: 0 as number
  }),

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.model = this.value;
        if (this.isEdit) {
          this.serviceId = this.model.order.service_type_id;
        } else {
          this.serviceId = this.serviceTypeId;
        }

        this.beneficiary = this.model.order.client;

        if (this.serviceId === 1) {
          this.goods = this.model.details.goods;
          this.outputs = this.model.details.outputs;
          this.releaseMode = this.model.order.release_mode;
          this.ataExpositon = this.model.order.for_cci_exposition;
        }
        this.calculateSums();
      }
    },
    oldValue: {
      immediate: true,
      handler() {
        this.oldModel = this.oldValue;
      }
    },
    extraVisitsAfter: {
      immediate: true,
      handler() {
        if (this.extraVisitsAfter > 0) {
          this.$emit("extraVisitsAfter", {
            service_id: this.services.extra_visits_after.value,
            sum_no_vat: this.extraVisitsAfterSum,
            sum_with_vat: this.addVat(this.extraVisitsAfterSum),
            quantity: this.extraVisitsAfter
          });
        } else {
          this.$emit("extraVisitsAfter", null);
        }
      }
    },
    isDataChanged: {
      immediate: true,
      handler() {
        if (this.isDataChanged && this.extraVisitsAfter === 0) {
          this.$emit("dataEdit", {
            service_id: this.services?.data_edit?.value,
            sum_no_vat: this.dataEditSum,
            sum_with_vat: this.addVat(this.dataEditSum),
            quantity: 1
          });
        } else {
          this.$emit("dataEdit", null);
        }
      }
    },
    additionalSum: {
      immediate: true,
      handler() {
        this.$emit("additionalSumChange", this.additionalSum);
      }
    },
    goods: {
      immediate: true,
      deep: true,
      handler() {
        if (this.goods && this.goods.length) {
          this.setGoodsService();
        }
      }
    },
    outputs: {
      immediate: true,
      deep: true,
      handler() {
        this.setOutputsService();
      }
    },
    beneficiary: {
      deep: true,
      handler() {
        this.setBeneficiaryService();
      }
    },
    releaseMode: {
      immediate: true,
      handler() {
        if (this.releaseMode) {
          this.setReleaseModeService();
        }
      }
    },
    servicesVatTotalSum: {
      immediate: true,
      handler() {
        this.$emit("setOrderSum", this.totalRemainedForPayServiceSUm);
      }
    }
  },

  computed: {
    showPrintInvoice() {
      return this.model?.order?.status !== "processing";
    },
    extraVisitsAfter() {
      return Number(this.extraOutputs);
    },
    dataEditSum() {
      if (this.isDataChanged && this.extraVisitsAfter === 0) {
        return this.getServicePrice(this.services.data_edit);
      }
      return 0;
    },
    additionalSum() {
      return this.extraVisitsAfterSum + this.dataEditSum;
    },
    isDataChanged() {
      return JSON.stringify(this.model) !== this.oldModel;
    },
    canAddExtraServices() {
      return (
        this.model?.order?.status?.alias === "issued" &&
        this.model.order.service_type_id === 1
      );
    },
    goodsSum() {
      return this.model.details.goods?.reduce(
        (
          total: number,
          { quantity, price }: { price: number; quantity: number }
        ) => {
          if (quantity && price) {
            return total + price * quantity;
          }
          return total;
        },
        0
      );
    },
    isDefaultService() {
      const services = this.model.details.services;
      const flag = services.some((service: any): boolean =>
        service.alias === "physical_normal" &&
        service.sum_with_vat > 0
      );

      return this.serviceId === 1 && this.role.alias === "beneficiary" && !flag;
    }
  },

  async mounted() {
    await this.getServices();
    if (!this.isEdit) {
      this.setBeneficiaryService();
    }
  },

  methods: {
    async getServices(): Promise<void> {
      try {
        const services = await this.$API
          .services()
          .getListByServiceType(this.serviceId);

        const orderServices = {} as any;

        for (const service of services) {
          orderServices[service.alias] = service;
        }

        this.services = { ...orderServices };
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    setGoodsService() {
      const serviceKey = "extra_positions";
      if (this.model.details.goods.length > 1) {
        const price = this.getServicePrice(
          this.services[serviceKey],
          this.goods.length,
          1
        );
        if (this.services.extra_positions) {
          const service = {
            service_id: this.services[serviceKey].value,
            sum_no_vat: price,
            sum_with_vat: this.addVat(price),
            quantity: this.goods.length - 1,
            name: this.services[serviceKey].text
          };

          this.checkService(serviceKey, service);
        }
      } else {
        this.removeService(serviceKey);
      }
    },
    setBeneficiaryService() {
      if (!this.beneficiary) {
        this.removeService("_normal", true);
        return;
      }

      if (this.isDefaultService) {
        this.setReleaseModeService();
        return;
      }

      if (this.serviceId === 1 && this.role.alias !== "beneficiary") {
        this.setAtaService();
        return;
      }

      if (this.serviceId === 2 && !this.isEdit) {
        this.setAAIJService();
        return;
      }

      if (this.serviceId === 3) {
        this.setCertificationService();
        return;
      }
    },
    setCertificationService() {
      const serviceKey = "cert";
      const price = this.getServicePrice(this.services.cert);

      if (this.services[serviceKey]) {
        const service = {
          service_id: this.services[serviceKey].value,
          sum_no_vat: price,
          sum_with_vat: this.addVat(price),
          quantity: 1,
          name: this.services[serviceKey].text
        };
        this.checkService(serviceKey, service);
      }
    },
    setAtaService(): void {
      const serviceKey = `${this.beneficiary.type}_normal`;
      this.removeService("_normal", true);

        if (this.services[serviceKey]) {
        const service = {
          service_id: this.services[serviceKey].value,
          sum_no_vat: 0,
          sum_with_vat: 0,
          quantity: 1,
          name: this.services[serviceKey].text
        };
        this.checkService(serviceKey, service);
        this.setReleaseModeService();
      }
    },
    setReleaseModeService(): void {
      const serviceKey = `${this.beneficiary.type}_${this.releaseMode}`;
      this.$emit("remove", [
        this.services.juridical_urgent?.value,
        this.services.physical_urgent?.value
      ]);
      if (this.releaseMode === 'normal') {
        this.removeService('_urgent', true);
      } else {
        this.removeService('_normal', true);
      }

      if (this.services[serviceKey]) {
        const service = {
          service_id: this.services[serviceKey].value,
          sum_no_vat: 0,
          sum_with_vat: 0,
          quantity: 1,
          name: this.services[serviceKey].text
        };
        this.checkService(serviceKey, service);
      }
      // if (this.services && this.releaseMode === "urgent") {
      //   this.$emit("remove", [
      //     this.services.juridical_urgent?.value,
      //     this.services.physical_urgent?.value
      //   ]);
      //   this.removeService('_normal', true);
      //   const serviceKey = `${this.beneficiary.type}_urgent`;
      //
      //   if (this.services[serviceKey]) {
      //     const service = {
      //       service_id: this.services[serviceKey].value,
      //       sum_no_vat: 0,
      //       sum_with_vat: 0,
      //       quantity: 1,
      //       name: this.services[serviceKey].text
      //     };
      //     this.checkService(serviceKey, service);
      //   }
      // } else if (this.services) {
      //   this.$emit("remove", [
      //     this.services.juridical_urgent?.value,
      //     this.services.physical_urgent?.value
      //   ]);
      // }
    },
    setOutputsService() {
      const serviceKey = `extra_visit`;
      if (this.outputs > 1) {
        const price = this.getServicePrice(
          this.services[serviceKey],
          this.outputs - 1
        );
        if (this.services[serviceKey]) {
          const service = {
            service_id: this.services[serviceKey].value,
            sum_no_vat: price,
            sum_with_vat: this.addVat(price),
            quantity: this.outputs - 1,
            name: this.services[serviceKey].text
          };
          this.checkService(serviceKey, service);
        }
      } else {
        this.removeService(serviceKey);
      }
    },
    checkService(serviceKey: string, service: any) {
      this.$emit("setService", service);

      this.calculateSums();
    },
    setAAIJService() {
      if (this.serviceId === 2) {
        const serviceKey = `aaij`;
        const price = this.getServicePrice(this.services.aaij);

        if (this.services[serviceKey]) {
          const service = {
            service_id: this.services[serviceKey].value,
            sum_no_vat: price,
            sum_with_vat: this.addVat(price),
            quantity: 1,
            name: this.services[serviceKey].text
          };
          this.checkService(serviceKey, service);
        }
      }
    },
    removeService(serviceKey: string, isDynamicKey = false): void {
      if (isDynamicKey) {
        this.$emit("remove", [
          this.services.juridical_normal?.value,
          this.services.physical_normal?.value
        ]);
      } else {
        this.$emit("remove", this.services[serviceKey]?.value);
      }
    },
    addVat(sum: number): number {
      return sum + (sum * 20) / 100;
    },
    setGuaranteePrice(): void {
      if (this.model.details.guaranty_required) {
        this.model.details.required_guaranty_sum =
          Math.trunc(0.3 * this.goodsSum) || 0;
      }
    },
    getServicePrice(service: any, value = 1, minusCount = 0) {
      if (service && service.values) {
        return (
          Number(
            service.values.find((el: any) => value >= el.min && value <= el.max)
              ?.value
          ) *
          (value - minusCount)
        );
      }
      return 0;
    },
    async previewReceipt() {
      try {
        const file = await this.$API
          .invoices()
          .getFileForPrintAta(this.model.order.id);
        if (file && file.url) {
          this.downloadFile(file);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    toggleDeleteService(service: any): void {
      service._isDeleted = !service._isDeleted;
      if (service.alias === "ata_exposition_discount") {
        this.model.order.for_cci_exposition = !service._isDeleted;
      }

      this.$forceUpdate();
    },
    can(action: string, subject: string): boolean {
      return (
        permissions.actions.includes(action) &&
        !permissions.subject.includes(subject)
      );
    },
    downloadFile(file: any) {
      const element = document.createElement("a");
      element.setAttribute("href", file.url);
      element.setAttribute("target", "_blank");
      element.click();
    },
    changeReleaseService(): void {
      const service = this.services[`${this.beneficiary.type}_urgent`];

      if (service && this.release_mode === "urgent") {
        this.model.details.services.push({
          name: service.text,
          service_id: service.value,
          sum_no_vat: this.getServicePrice(service),
          sum_with_vat: this.addVat(this.getServicePrice(service)),
          quantity: 1
        });
      } else if (service && this.release_mode === "normal") {
        const serviceIndex = this.model.details.services.findIndex(
          (item: any) => item.alias === `${this.beneficiary.type}_urgent`
        );
        if (serviceIndex !== -1) {
          this.model.details.services?.splice(serviceIndex, 1);
        }
      }
      this.forceKey++;
    },
    updateVat(index: number): void {
      this.$emit("updateVat", index);
      this.calculateSums();
    },
    calculateSums() {
      if (this.model?.details?.services) {
        const service = this.model.details.services;

        this.servicesTotalSum = service?.reduce(
          (total: number, { sum_no_vat }: { sum_no_vat: number }) => {
            if (sum_no_vat) {
              return total + Number(sum_no_vat);
            }
            return total;
          },
          0
        );

        this.servicesVatTotalSum = service?.reduce(
          (total: number, { sum_with_vat }: { sum_with_vat: number }) => {
            if (sum_with_vat) {
              return total + Number(sum_with_vat);
            }
            return total;
          },
          0
        );

        this.totalPayedServiceSUm = service?.reduce(
          (total: number, { paid_sum }: { paid_sum: number }) => {
            if (paid_sum) {
              return total + Number(paid_sum);
            }
            return total;
          },
          0
        );

        this.totalRemainedForPayServiceSUm = service?.reduce(
          (total: number, { remained_sum }: { remained_sum: number }) => {
            if (remained_sum) {
              return total + Number(remained_sum);
            }
            return total;
          },
          0
        );
        this.$emit(
          "input",
          this.model.details.services.reduce(
            (total: number, { sum_with_vat }: { sum_with_vat: number }) => {
              if (sum_with_vat) {
                return total + Number(sum_with_vat);
              }
              return total;
            },
            0
          )
        );
      }
    }
  }
});
