import Vue from "vue";
export default Vue.extend({
  methods: {
    async printOrder(): Promise<void> {
      try {
        const file = await this.$API.orders().printOrder(this.model.order.id);
        this.downloadFile(file);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    downloadFile(file: any) {
      const element = document.createElement("a");
      element.setAttribute("href", file.url);
      element.setAttribute("target", "_blank");
      element.click();
    },
    async cloneOrder(): Promise<void> {
      try {
        await this.$API.orders().cloneOrder(this.model.order.id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("orders.modals.alert.successful_cloned")
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    changeOrderStatus(): void {
      this.$emit("showStatusDialog");
    }
  }
});
