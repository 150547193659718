import Vue from "vue";
export default Vue.extend({
  data: () => ({
    paymentsTotalPrice: 0 as number
  }),

  methods: {
    getServices(services: Array<any>) {
      this.model.details.services = services;
    },
    changeKey() {
      this.forceKey = this.forceKey++;
    },
    updateVat(index: number): void {
      this.model.details.services[index].sum_no_vat =
        Math.ceil(Number(this.model.details.services[index].sum_no_vat) * 100) /
        100;
      this.model.details.services[index].sum_with_vat =
        Math.ceil(
          this.addVat(Number(this.model.details.services[index].sum_no_vat)) *
            100
        ) / 100;
      this.model.details.services[index].remained_sum =
        Math.ceil(
          (Number(this.model.details.services[index].sum_with_vat) -
            Number(this.model.details.services[index].paid_sum)) *
            100
        ) / 100;
      this.getTotalSum();
    },
    addVat(sum: number): number {
      return Math.ceil((sum + (sum * 20) / 100) * 100) / 100;
    },
    removeService(service: any) {
      if (Array.isArray(service)) {
        for (const item of service) {
          const serviceIndex = this.model.details.services.findIndex(
            (el: any) => el.service_id === item
          );
          if (serviceIndex !== -1) {
            this.model.details.services.splice(serviceIndex, 1);
          }
        }
      } else {
        const serviceIndex = this.model.details.services.findIndex(
          (item: any) => item.service_id === service
        );
        if (serviceIndex !== -1) {
          this.model.details.services.splice(serviceIndex, 1);
        }
      }
      this.getTotalSum();
    },
    setService(service: any): void {
      if (
        !this.model.details?.services?.some(
          (item: any) => item.service_id === service.service_id
        )
      ) {
        this.model.details.services.push(service);
      } else {
        const serviceIndex = this.model.details.services.findIndex(
          (item: any) => item.service_id === service.service_id
        );

        this.model.details.services[serviceIndex] = {
          ...this.model.details.services[serviceIndex],
          sum_with_vat: service.sum_with_vat,
          sum_no_vat: service.sum_no_vat,
          quantity: service.quantity
        };
      }
      this.getTotalSum();
    },
    getTotalSum() {
      this.totalSum = this.model.details.services.reduce(
        (total: number, { sum_with_vat }: { sum_with_vat: number }) => {
          if (sum_with_vat) {
            return total + Number(sum_with_vat);
          }
          return total;
        },
        0
      );
    },
    loadDocuments(val: any) {
      this.model.documents = val;
    },
    getErrorIndex(item: string): void {
      const [, , errorIndex] = item.split(".");

      if (!this.panel.some((item: number) => item === Number(errorIndex))) {
        this.panel.push(Number(errorIndex));
      }
    },
    setPaymentsPrice(price: number) {
      this.paymentsTotalPrice = price;
    }
  }
});
